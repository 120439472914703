import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { StripeCardElementChangeEvent, StripeElementChangeEvent } from '@stripe/stripe-js';
import { useElements } from '@stripe/react-stripe-js';
import { StripePaymentElements } from '@/components/paymentProviders/Stripe/typedefs';
import { EMPTY_CHECKOUT_FIELDS_ERROR } from '@/components/paymentProviders/Stripe/constants';

interface Result {
  isFormFilled: boolean;
  error: StripeCardElementChangeEvent['error'] | undefined;
  setError: Dispatch<SetStateAction<StripeCardElementChangeEvent['error'] | undefined>>;
  isPristine: boolean;
}

export const useStripeValidation = (element: StripePaymentElements): Result => {
  const [
    isFormFilled,
    setIsFormFilled,
  ] = useState<boolean>(false);

  const [
    error,
    setError,
  ] = useState<StripeCardElementChangeEvent['error'] | undefined>();

  const [
    isPristine,
    setIsPristine,
  ] = useState(true);

  const elements = useElements();

  useEffect(() => {
    if (!elements) {
      return () => { /* */ };
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const paymentElement = elements?.getElement(element);

    const handler = (event: StripeElementChangeEvent) => {
      setIsFormFilled(!!event.complete);

      if (!event.empty) {
        setIsPristine(false);
      } else {
        setIsPristine(true);
      }

      if (event.error) {
        setError(event.error);

        return;
      }

      if (error && !event.error) {
        setError(undefined);

        return;
      }

      if (
        !event.empty
        && error
        && error?.code === EMPTY_CHECKOUT_FIELDS_ERROR.code
      ) {
        setError(undefined);
      }
    };

    // Stripe typings for this method are incorrect and the last event in the doc
    // is recognized as only possible

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    paymentElement?.on('change', handler);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return () => paymentElement?.off('change', handler);
  }, [
    elements,
    element,
    error,
    isPristine,
  ]);

  return {
    isFormFilled,
    error,
    setError,
    isPristine,
  };
};
