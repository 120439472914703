import {
  PricingOptionPaymentType,
  SubscriptionPlanPaymentType,
} from '@/controllers/graphql/generated';

export const checkIfPlanIsFreeTrial = (options: {
  subscriptionPlan: {
    paymentType: SubscriptionPlanPaymentType;
  };
  pricingOption: {
    paymentType: PricingOptionPaymentType;
    salePrice: number | null;
  } | null;
}): boolean => {
  const { subscriptionPlan, pricingOption } = options;

  if (pricingOption) {
    return pricingOption.paymentType === PricingOptionPaymentType.Trial
      && pricingOption.salePrice === 0;
  }

  return subscriptionPlan.paymentType === SubscriptionPlanPaymentType.FreeTrial;
};
