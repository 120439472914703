import { PaymentStatus } from '@/controllers/graphql/generated';
import { allRoutes, renderRoute } from '@/controllers/router/allRoutes';

export const freeSubscriptionType = 'free';

export const FAIL_PAYMENT_STATUSES = [
  PaymentStatus.Failed,
  PaymentStatus.Declined,
];

export const TEMPORARY_EMAIL_PATTERN = /\b[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}@temporary\.com\b/;

export const ACTIVATION_QUIZ_ROUTES = [
  /**
   * NOTE: `_` means relative route.
   * So, it doesn't matter study or learn.
   * And we don't need professionSlug to check
   * if URL contains the needed moduleSlug or courseTopicSlug.
   */
  renderRoute(allRoutes.study.profession._.courseTopic, {
    courseTopicSlug: 'test-drive-activation-intro-en',
    moduleSlug: 'test-drive-en',
  }),
  renderRoute(allRoutes.study.profession._.courseTopic, {
    courseTopicSlug: 'py-td-intro-en',
    moduleSlug: 'py-test-drive-en',
  }),
];
